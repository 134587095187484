/* Remove the black outline when focused */
.ProseMirror:focus {
    outline: none;
}
.ProseMirror > * + * {
	margin-top: 0.75em;
}
.ProseMirror ul, .ProseMirror ol {
	padding: 0 1rem;
}
.ProseMirror p {
	margin: 0;
}




 .ProseMirror span::selection{
	background-color:blue;
	color: currentColor;
}  
/* blockquote {
	padding-left: 1rem;
	border-left: 2px solid rgba(13, 13, 13, 0.1);
} */

.ProseMirror p.is-editor-empty:first-child::before {
	content: attr(data-placeholder);
	float: left;
	color: #adb5bd;
	pointer-events: none;
	height: 0;
  }